import mock from "@/fake-db/mock.js"

const data =  {
    passcode: "654321", // Fake passcode
}


// GET: Fetch Todos
mock.onGet('/api/passcode').reply(() => {
    const response = data.passcode
    return [200, response]
})